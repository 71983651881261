/*=========================================================================================
  File Name: moduleCountryMutations.js
  Description: Country Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.OffersNotAccepteds.unshift(item)
  },
  SET_OffersNotAccepteds(state, OffersNotAccepteds) {
    debugger;
    try {
     
      state.OffersNotAccepteds = OffersNotAccepteds;
      
    } catch (error) {
      console.error("Failed to parse OffersNotAccepteds data JSON:", error);
      state.OffersNotAccepteds = [];
    }
  },
  UPDATE_OffersNotAccepted(state, OffersNotAccepted) {
    debugger
    const OffersNotAcceptedIndex = state.OffersNotAccepteds.findIndex((p) => p.ID == OffersNotAccepted.Id)
    Object.assign(state.OffersNotAccepteds[OffersNotAcceptedIndex], OffersNotAccepted)
  },
  REMOVE_ITEM(state, itemId) {
      const ItemIndex = state.OffersNotAccepteds.findIndex((p) => p.Id == itemId)
      state.OffersNotAccepteds.splice(ItemIndex, 1)
  },
}
